import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class HighlightTitleService {

  private isSelectPlanActiveSubject = new BehaviorSubject<boolean>(false);
  isSelectPlanActive$ = this.isSelectPlanActiveSubject.asObservable();

  constructor() { }

  setIsSelectPlanActive(isActive: boolean) {
    this.isSelectPlanActiveSubject.next(isActive);
  }
  
}
